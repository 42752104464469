import styles from './artikel.module.scss';
import useAuthStore from '../store/useAuth';
import GlobalFilter, {
  useGlobalFilter,
} from '../components/DataTable/GlobalFilter';
import BooleanIcon from 'components/BooleanIcon/BooleanIcon';
import DataTable from 'components/DataTable/DataTable';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import React, { useState, useEffect, useMemo, useRef } from 'react';

import {
  unstable_useFormState as useFormState,
  unstable_FormCheckbox as FormCheckbox,
  unstable_Form as ReakitForm,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';

import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import Checkbox from '@wienenergiegithub/ui-next/src/common/components/Checkbox/Checkbox';

import { Link } from 'gatsby';

import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import classNames from 'classnames/index';

const AvailabilityPopover = ({
  articleIndex,
  facilities = [],
  userCanEdit,
  form,
}) => {
  const popover = usePopoverState({
    placement: 'bottom-start',
    unstable_preventOverflow: true,
  });

  if (userCanEdit) {
    return (
      <>
        <PopoverDisclosure
          className={classNames(styles.popoverButton, styles.facilityHeading)}
          {...popover}
        >
          {facilities.map(facility => (
            <BooleanIcon
              isTrue={
                form.values?.articles?.[`article-${articleIndex}`]?.[
                  `facility-${facility.id}`
                ]
              }
              key={facility.id}
            />
          ))}
        </PopoverDisclosure>
        {popover.visible && (
          <Popover {...popover} aria-label="Welcome">
            <Card className={styles.popover} variant="elevated">
              <div
                className={classNames(
                  styles.checkBoxWrapper,
                  styles.popoverGrid,
                )}
              >
                {facilities?.map(facility => (
                  <Heading
                    size="h7"
                    as="h5"
                    className={styles.tableHeading}
                    key={facility.id}
                  >
                    {facility.name[0]}
                  </Heading>
                ))}

                {facilities?.map(facility => (
                  <FormCheckbox
                    name={[
                      'articles',
                      `article-${articleIndex}`,
                      `facility-${facility.id}`,
                    ]}
                    as={Checkbox}
                    key={`${facility.id}checkbox`}
                    {...form}
                  />
                ))}
              </div>
            </Card>
          </Popover>
        )}
      </>
    );
  }

  return (
    <div className={classNames(styles.facilityHeading)}>
      {facilities.map(facility => (
        <BooleanIcon
          isTrue={
            form.values?.articles?.[`article-${articleIndex}`]?.[
              `facility-${facility.id}`
            ]
          }
          key={facility.id}
        />
      ))}
    </div>
  );
};

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});

  throw errors;
};

const ArticlePage = () => {
  const stickyRef = useRef(null);
  const [articles, setArticles] = useState([]);
  const [facilities, setFacilities] = useState();
  const notifications = useNotification();
  const { globalFilter, updateGlobalFilter } = useGlobalFilter();

  const userCan = useAuthStore(state => state.userCan);

  const userCanEdit = userCan('edit article');

  const form = useFormState({
    values: { articles: [] },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);
      try {
        const { data } = await api({
          url: `/backend/api/articles-restrictions`,
          method: 'PUT',
          data: {
            ...values,
          },
        });
        setArticles(data?.data);
        form.update('articles', data?.meta?.restrictions);

        // random id for notification popup
        notifications.add({
          title: 'Artikel Anlagenzuweisung wurde gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
        createLaravelFormError(error);
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const responses = await Promise.all([
          api.get(`/backend/api/articles`),
          api.get(`/backend/api/facilities`),
        ]);
        setArticles(responses[0]?.data?.data);
        form.update('articles', responses[0]?.data?.meta?.restrictions);
        setFacilities(responses[1]?.data?.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'MA48 ID',
        accessor: 'ma48_id',
      },
      {
        Header: 'Kategorie',
        accessor: 'category.internal_name',
      },
      {
        Header: 'ASN',
        accessor: 'asn',
      },
      {
        Header: () => (
          <div className={styles.facilityHeading}>
            {facilities?.map(facility => (
              <strong className={styles.tableHeading} key={facility.id}>
                {facility.name[0]}
              </strong>
            ))}
          </div>
        ),
        accessor: 'facilities',
        Cell: props => (
          <AvailabilityPopover
            articleIndex={props.row.original.id}
            facilities={facilities}
            userCanEdit={userCanEdit}
            form={form}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Kommentar',
        accessor: 'comment',
      },
      {
        Header: 'BLS ID',
        accessor: 'bls_id',
      },
      {
        Header: 'BLS Order',
        accessor: 'bls_order',
      },
      {
        Header: 'MA48 Beschreibung',
        accessor: 'ma48_description',
      },
      ...(userCanEdit
        ? [
            {
              accessor: 'action',
              Cell: ({
                row: {
                  original: { id },
                },
              }) => (
                <Button
                  as={Link}
                  variant="link"
                  to={`/artikeldaten?article=${id}`}
                >
                  Bearbeiten
                </Button>
              ),
              disableSortBy: true,
            },
          ]
        : []),
    ],
    [form, articles],
  );

  return (
    <Wrapper>
      <Layout title="Artikel">
        <StickyBar ref={stickyRef}>
          <div className={styles.actions}>
            <GlobalFilter {...globalFilter} />
            <div className={styles.actionButtons}>
              <Button
                variant="link"
                theme="primary"
                as={Link}
                to="/artikeldaten"
                className={styles.newButton}
              >
                Neuen Artikel anlegen
              </Button>
              {userCanEdit && (
                <FormSubmitButton
                  form="save"
                  as={Button}
                  className={styles.submit}
                  {...form}
                >
                  Speichern
                </FormSubmitButton>
              )}
            </div>
          </div>
        </StickyBar>
        <ReakitForm {...form} id="save">
          <Container>
            {articles && facilities && (
              <DataTable
                data={articles}
                form={form}
                columns={columns}
                hasStickyHeader
                offset={{ top: stickyRef.current?.clientHeight }}
                onUpdateGlobalFilter={updateGlobalFilter}
              />
            )}
            {!articles && (
              <Heading size="h3">Artikel werden geladen...</Heading>
            )}
          </Container>
        </ReakitForm>
      </Layout>
    </Wrapper>
  );
};

export default ArticlePage;
